import payload_plugin_Hvi4SnMi2I from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.28.1_typescript@5.5.4_vue@3.5.13_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_uSHVSjWxjO from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.14.0_jiti@2.4.1__ioredis@5.4.1_6ur5avk5ociithixznkgjhspdy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CmV2pksT6W from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.14.0_jiti@2.4.1__ioredis@5.4.1_6ur5avk5ociithixznkgjhspdy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6rn9mNDSdi from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.14.0_jiti@2.4.1__ioredis@5.4.1_6ur5avk5ociithixznkgjhspdy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_mutpwPai6j from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_sass@1.8_ugkcjnvac3q4q2uc7izy2ymonu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_bNs4w9gulv from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.14.0_jiti@2.4.1__ioredis@5.4.1_6ur5avk5ociithixznkgjhspdy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_D13LC8i6AS from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.14.0_jiti@2.4.1__ioredis@5.4.1_6ur5avk5ociithixznkgjhspdy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vpXwPewrwy from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.14.0_jiti@2.4.1__ioredis@5.4.1_6ur5avk5ociithixznkgjhspdy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_NzLn22hDMY from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.14.0_jiti@2.4.1__ioredis@5.4.1_6ur5avk5ociithixznkgjhspdy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/vercel/path0/main-site/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_rZGYP4RnTv from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.28.1_typescript@5.5.4_vue@3.5.13_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/main-site/.nuxt/components.plugin.mjs";
import prefetch_client_wCzyGBAvLU from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.14.0_jiti@2.4.1__ioredis@5.4.1_6ur5avk5ociithixznkgjhspdy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_uFzeebClSD from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Q4JF9i4nip from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import siteConfig_AhVdhmMYyy from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_sass@_2dzkmin57rgy7g3ti3tomrwzti/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_qqXEKU8xJZ from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_sass@_2dzkmin57rgy7g3ti3tomrwzti/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_oS1oT0aPIL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_aFl50BY8Jq from "/vercel/path0/node_modules/.pnpm/nuxt-quasar-ui@2.1.7_@quasar+extras@1.16.15_magicast@0.3.5_quasar@2.17.2_rollup@4.28.1/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import rest_api_zNWx1STPxo from "/vercel/path0/main-site/plugins/rest-api.js";
import gtm_MVzSxF8h1r from "/vercel/path0/main-site/plugins/gtm.js";
import gtag_bAM3HbiZET from "/vercel/path0/main-site/plugins/gtag.js";
import error_handler_kEP5FliEXj from "/vercel/path0/main-site/plugins/error-handler.ts";
import image_error_Tix9NvX1QD from "/vercel/path0/main-site/plugins/image-error.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/main-site/plugins/sentry.ts";
import init_5O4w5Qp4rL from "/vercel/path0/node_modules/.pnpm/nuxt-schema-org@3.4.1_@unhead+vue@1.11.13_vue@3.5.13_typescript@5.5.4___magicast@0.3.5_rollup_bfcjictchme2mn3h5peztmguzu/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/i18n/init.js";
export default [
  payload_plugin_Hvi4SnMi2I,
  revive_payload_client_uSHVSjWxjO,
  unhead_CmV2pksT6W,
  router_6rn9mNDSdi,
  _0_siteConfig_mutpwPai6j,
  payload_client_bNs4w9gulv,
  navigation_repaint_client_D13LC8i6AS,
  check_outdated_build_client_vpXwPewrwy,
  chunk_reload_client_NzLn22hDMY,
  plugin_zbacBAXhj0,
  plugin_vue3_rZGYP4RnTv,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wCzyGBAvLU,
  switch_locale_path_ssr_uFzeebClSD,
  i18n_Q4JF9i4nip,
  siteConfig_AhVdhmMYyy,
  inferSeoMetaPlugin_qqXEKU8xJZ,
  plugin_oS1oT0aPIL,
  plugin_aFl50BY8Jq,
  rest_api_zNWx1STPxo,
  gtm_MVzSxF8h1r,
  gtag_bAM3HbiZET,
  error_handler_kEP5FliEXj,
  image_error_Tix9NvX1QD,
  sentry_3AyO8nEfhE,
  init_5O4w5Qp4rL
]