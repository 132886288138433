/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { jwtDecode } from "jwt-decode";

export interface JwtResponseDTO {
  /** Access token */
  accessToken: string
  /** Refresh token */
  refreshToken: string
}

export interface SignUpDTO {
  /** Email address */
  email: string
  /** Password */
  password?: string
  /** Username */
  username: string
}

export interface SignUpCheckDTO {
  /** Email address */
  email: string
  /** Username */
  username: string
}

export type Error = object

export interface SignUpCheckResponseDTO {
  /** Errors */
  errors: Error[]
}

export interface SignUpConfirmDTO {
  /** Email address */
  email: string
  /** Token from letter */
  token: string
}

export interface SignUpAppendDTO {
  /** Email address */
  email: string
  /** New password */
  password?: string
}

export interface SignUpAppendConfirmDTO {
  /** Email address */
  email: string
  /** New password */
  password: string
  /** Token from email */
  token: string
}

export interface SignUpResendDTO {
  /** Email address */
  email: string
}

export interface SignInDTO {
  /** Login */
  login: string
  /**  Password */
  password: string
}

export interface PasswordRecoveryDTO {
  /** Email address */
  email: string
  /** New password */
  password?: string
}

export interface PasswordRecoveryConfirmDTO {
  /** Email address */
  email: string
  /** New password */
  password: string
  /** Token from email */
  token: string
}

export interface PasswordChangeDTO {
  /** New password */
  password?: string
  /** Old password */
  oldPassword?: string
}

export interface PasswordChangeConfirmDTO {
  /** New password */
  password: string
  /** Token from email */
  token: string
}

export interface UserTOTPDTO {
  /** Secret */
  secret: string
  /** qr-code */
  qr: string
}

export interface UserLoginAttemptDTO {
  /** Counter */
  counter: number
  /** Locked till */
  lockedTill?: number
}

export interface UserSettingsDTO {
  /** User old status */
  oldStatus?: 'new' | 'active' | 'draft' | 'banned' | 'deleted' | 'temporary' | 'service'
  /** Subscribe to news */
  subscriptionNews?: boolean
  /** Language */
  language?: string
  /** Timezone */
  timezone?: string
  /** Register finished */
  isRegistered?: boolean
  /** 2FA saved by user */
  is2FASaved?: boolean
  /** 2FA settings */
  totp?: UserTOTPDTO
  /** Login attempt */
  loginAttempt?: UserLoginAttemptDTO
}

export interface FileDTO {
  /** Unique identifier */
  id: string
  /** Name of file */
  name: string
  /** Extension of file */
  ext: string
  /** Url of file */
  url: string
  /** Url of file */
  originUrl: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface UserDTO {
  /** Unique identifier */
  id: string
  /** User email address */
  email?: string
  /** User name */
  username?: string
  /** First name */
  firstName?: string
  /** Last name */
  lastName?: string
  /** User role */
  role: 'default' | 'guest' | 'admin' | 'service'
  /** User status */
  status: 'new' | 'active' | 'draft' | 'banned' | 'deleted' | 'temporary' | 'service'
  /** User settings */
  settings: UserSettingsDTO
  /** Avatar id */
  avatar?: string
  /** Avatar file */
  avatarFile?: FileDTO
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface UpdateUserSettingsDTO {
  /** Subscribe to news */
  subscriptionNews?: boolean
}

export interface UpdateUserDTO {
  /** User email address */
  email?: string
  /** User name */
  username?: string
  /** First name */
  firstName?: string
  /** Last name */
  lastName?: string
  /** Avatar id */
  avatar?: string
  /** Update user settings */
  settings?: UpdateUserSettingsDTO
}

export interface UnauthorizedExceptionDTO {
  /** @example 401 */
  statusCode: number
  /** @example "Unauthorized" */
  message: string
  /** @example "Unauthorized" */
  error: string
}

export interface ProviderReferralLinkDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /**
   * Expired at
   * @format date-time
   */
  expiredAt?: string
  /** Provider id */
  providerId: string
  /** Provider link */
  link: string
  /** Provider description */
  description?: string
  /** Default link for provider */
  default?: boolean
}

export interface ProviderResponseDTO {
  /** Unique identifier */
  id: string
  /** Name of company */
  name: string
  /** URL of company */
  url: string
  /** Logo of company */
  logoFile?: FileDTO
  /** Provider referral links */
  referralLinks: ProviderReferralLinkDTO[]
}

export interface CreateProviderReferralLinkDTO {
  /**
   * Expired at
   * @format date-time
   */
  expiredAt?: string
  /** Provider link */
  link: string
  /** Provider description */
  description?: string
  /** Default link for provider */
  default?: boolean
}

export interface BadRequestExceptionDTO {
  /** @example 400 */
  statusCode: number
  /** @example "Bad request" */
  message: string
  /** @example "Bad request" */
  error: string
}

export interface NotFoundExceptionDTO {
  /** @example 404 */
  statusCode: number
  /** @example "Not Found" */
  message: string
  /** @example "Not Found" */
  error: string
}

export interface UpdateProviderReferralLinkDTO {
  /**
   * Expired at
   * @format date-time
   */
  expiredAt?: string
  /** Provider link */
  link?: string
  /** Provider description */
  description?: string
  /** Default link for provider */
  default?: boolean
}

export interface CompanyResponseDTO {
  /** Unique identifier */
  id: string
  /** Name of company */
  name: string
  /** Description of company */
  description: string
  /** URL of company */
  url: string
  /** Industry of company */
  industry: string
  /**
   * Founding year of company
   * @format date-time
   */
  foundingYear: string
  /** Logo of company */
  logoFile?: FileDTO
}

export interface CreateCompanyDTO {
  /** Name of company */
  name: string
  /** Description of company */
  description: string
  /** URL of company */
  url: string
  /** Unique identifier of logo of company */
  logo: string
  /** Industry of company */
  industry: string
  /**
   * Founding year of company
   * @format date-time
   */
  foundingYear: string
}

export interface UpdateCompanyDTO {
  /** Name of company */
  name: string
  /** Description of company */
  description: string
  /** URL of company */
  url: string
  /** Unique identifier of logo of company */
  logo: string
  /** Industry of company */
  industry: string
  /**
   * Founding year of company
   * @format date-time
   */
  foundingYear: string
}

export interface PartnerResponseDTO {
  /** Subtitle */
  subtitle?: string
  /** Unique identifier */
  id: string
  /** Name of company */
  name: string
  /** Description of company */
  description: string
  /** URL of company */
  url: string
  /** Industry of company */
  industry: string
  /**
   * Founding year of company
   * @format date-time
   */
  foundingYear: string
  /** Logo of company */
  logoFile?: FileDTO
}

export interface CreatePartnerDTO {
  /** Name of company */
  name: string
  /** URL of company */
  url: string
  /** Unique identifier of logo of company */
  logo: string
  /** Subtitle */
  subtitle?: string
}

export interface UpdatePartnerDTO {
  /** Name of company */
  name: string
  /** URL of company */
  url: string
  /** Unique identifier of logo of company */
  logo: string
  /** Subtitle */
  subtitle?: string
}

export interface ChainDTO {
  /** Chain id by chain-list */
  id: number
  /** Name of chain */
  name: string
  /** Chain type */
  type: 'evm'
  /** Scan url */
  url: string
}

export interface GetChartsResponseDTO {
  /** Token total supply */
  totalSupply: string
  /** Token circulating */
  circulatingSupply: string
  /** Token available for purchase */
  availableSupply: string
  /**
   * Date of price
   * @format date-time
   */
  time: string
}

export interface CountryDTO {
  /** @example "China" */
  name: string
  /** @example "CN" */
  code: string
}

export interface ImportFileItemDTO {
  /** Name of file */
  name: string
  /** Url of file */
  url: string
}

export interface ImportFileDTO {
  files: ImportFileItemDTO[]
}

export interface TokenHolderDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Token id */
  tokenId: string
  /** Wallet address */
  address: string
  /** wallet token balance */
  balance: string
}

export interface TokenIssuerResponseDTO {
  /** Unique identifier */
  id: string
  /** Name of company */
  name: string
  /** Description of company */
  description: string
  /** URL of company */
  url: string
  /** Industry of company */
  industry: string
  /**
   * Founding year of company
   * @format date-time
   */
  foundingYear: string
  /** Logo of company */
  logoFile?: FileDTO
}

export interface TokenResponseDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Token chain id */
  chainId?: number
  /** Chain name */
  address?: string
  /** Token name */
  name?: string
  /** Token symbol */
  symbol?: string
  /** Token decimals */
  decimals?: number
  /** Token total supply */
  totalSupply?: string
  /** Token circulating */
  circulatingSupply?: string
  /** Token available for purchase */
  availableSupply?: string
  /** Description */
  description?: string
  /** Icon */
  icon?: string
  /** Jurisdiction */
  jurisdiction?: string
  /** Issuer unique identifier */
  issuerId?: string
  /** URL scan */
  url?: string
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: string
  /** Token available for purchase */
  availableSupplyPercentage?: number
  /** Chain */
  chain?: ChainDTO
  /** Wallet balances */
  holders?: TokenHolderDTO[]
  /** Issuer */
  issuer?: TokenIssuerResponseDTO
}

export interface PaginationResultDto {
  /** Number of results */
  count: number
  /** Array of results */
  rows: LandResponseDTO[]
}

export interface CreateTokenIssuerDTO {
  /** Name of company */
  name: string
  /** Description of company */
  description: string
  /** URL of company */
  url: string
  /** Unique identifier of logo of company */
  logo: string
  /** Industry of company */
  industry: string
  /**
   * Founding year of company
   * @format date-time
   */
  foundingYear: string
}

export interface CreateTokenPayloadDTO {
  /** Token chain id */
  chainId?: number
  /** Chain name */
  address?: string
  /** Token name */
  name?: string
  /** Token symbol */
  symbol?: string
  /** Token decimals */
  decimals?: number
  /** Token total supply */
  totalSupply?: string
  /** Token circulating */
  circulatingSupply?: string
  /** Token available for purchase */
  availableSupply?: string
  /** Description */
  description?: string
  /** Icon */
  icon?: string
  /** Jurisdiction */
  jurisdiction?: string
  /** Issuer unique identifier */
  issuerId?: string
  /** Price */
  price?: number
  /** Issuer */
  issuer?: CreateTokenIssuerDTO
}

export interface UpdateTokenPayloadDTO {
  /** Token circulating */
  circulatingSupply?: string
  /** Token available for purchase */
  availableSupply?: string
  /** Description */
  description?: string
  /** Icon */
  icon?: string
  /** Jurisdiction */
  jurisdiction?: string
  /** Currency */
  currency?: string
  /** Price */
  price?: number
}

export interface UpdateTokenIssuerDTO {
  /** Exist issuer ids */
  issuerId?: string
  /** New issuer */
  issuer?: CreateTokenIssuerDTO
}

export interface UnitDocumentDTO {
  /** Title */
  name: string
  /** URL */
  url: string
}

export interface CreateProviderDTO {
  /** Name of company */
  name: string
  /** URL of company */
  url: string
  /** Unique identifier of logo of company */
  logo: string
  /** Provider type */
  type: 'Estate' | 'Land' | 'Other'
}

export interface UnitFilePayloadDTO {
  /** Unique identifier */
  fileId: string
  /** Unique identifier */
  type: 'image' | 'document' | 'covers'
}

export interface CreateUnitPayloadDTO {
  /** Sanitized name */
  sanitizeName: string
  /** Unit type */
  type: 'Estate' | 'Land' | 'Other'
  /** Provider id */
  providerId: string
  /** Token id */
  tokenId?: string
  /** NFT id */
  nftId?: number
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Translations */
  translations?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents?: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Referral link ID */
  referralLinkId?: string
  /** List of exist partner ids */
  partnerIds: string[]
  /** Create partners */
  partners: UpdatePartnerDTO[]
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating?: number
  /** Finance */
  finance: EstateFinanceDTO | LandFinanceDTO
  /** Create provider */
  provider: CreateProviderDTO
  /** Token */
  token: CreateTokenPayloadDTO
  /** Files */
  files: UnitFilePayloadDTO[]
  /** Referral link */
  referralLink?: string
  /** Estate tags */
  tags: 'trending'[]
}

export interface LandFinanceDTO {
  /** Land Value */
  value: number
  /** Tax assessed value */
  taxAssessedValue: number
  /** Annual Tax Amount */
  annualTaxAmount: number
}

export interface UnitFileDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Unique identifier */
  estateId: string
  /** Unique identifier */
  fileId: string
  /** Unique identifier */
  type: 'image' | 'document' | 'covers'
  /** File */
  file: FileDTO
}

export interface MetricsDTO {
  /** 24h */
  viewsPerDay: number
  /** all time */
  viewsForAllTime: number
}

export interface UnitSimilarDTO {
  /** Unique identifier */
  id: string
  /** Sanitized name */
  sanitizeName: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Unit type */
  type: 'Estate' | 'Land' | 'Other'
  /** Token id */
  tokenId: string
  /** NFT id */
  nftId?: number
  /** Status */
  status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Minimum investment value in USD */
  minimumInvestmentUSD?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Provider id */
  providerId: string
  /** External unique identifier */
  externalId: string
  /** External url */
  externalUrl: string
  /** Unit has new data */
  newData: boolean
  /** Translations */
  translations?: string
  /** Referral link ID */
  referralLinkId?: string
  /** Blocked fields */
  blockedField?: (
    | 'countryId'
    | 'location'
    | 'coords'
    | 'currency'
    | 'price'
    | 'minimumInvestment'
    | 'finance'
    | 'name'
    | 'description'
    | 'translations'
    | 'files'
    | 'documents'
    | 'area'
    | 'ownershipStatus'
    | 'ownershipText'
    | 'referralLinkId'
    | 'stage'
    | 'irr'
    | 'apr'
    | 'bathroom'
    | 'bedroom'
    | 'utilities'
    | 'currentUse'
  )[]
  /** View count by all time */
  viewsForAllTime?: number
  /** View count by day */
  viewsPerDay?: number
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance */
  finance?: LandFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating: number
  /** Return On Investment */
  roi?: number
  /** Profitability index */
  pi?: number
  /** Priority output */
  priority: number
  /**
   * Utilities
   * @default "unknown"
   */
  utilities?: 'yes' | 'no' | 'unknown'
  /** Current use */
  currentUse?: 'residential' | 'commercial' | 'industrial' | 'educational' | 'entertainment' | 'other'
  /** Is favorite */
  favorite: boolean
  /** Files */
  files: UnitFileDTO[]
  /** Country */
  country?: CountryDTO
  /** Token */
  token: TokenResponseDTO
  /** Metrics */
  metrics: MetricsDTO
  /** Tags */
  tags: string[]
  /** Platform provider */
  provider: ProviderResponseDTO
  /** Referral link */
  referralLink?: ProviderReferralLinkDTO
}

export interface UnitResponseDTO {
  /** Unique identifier */
  id: string
  /** Sanitized name */
  sanitizeName: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Unit type */
  type: 'Estate' | 'Land' | 'Other'
  /** External url */
  externalUrl: string
  /** Token id */
  tokenId: string
  /** NFT id */
  nftId?: number
  /** Status */
  status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Unit has new data */
  newData: boolean
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Minimum investment value in USD */
  minimumInvestmentUSD?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Provider id */
  providerId: string
  /** External unique identifier */
  externalId: string
  /** Translations */
  translations?: string
  /** Referral link ID */
  referralLinkId?: string
  /** Blocked fields */
  blockedField?: (
    | 'countryId'
    | 'location'
    | 'coords'
    | 'currency'
    | 'price'
    | 'minimumInvestment'
    | 'finance'
    | 'name'
    | 'description'
    | 'translations'
    | 'files'
    | 'documents'
    | 'area'
    | 'ownershipStatus'
    | 'ownershipText'
    | 'referralLinkId'
    | 'stage'
    | 'irr'
    | 'apr'
    | 'bathroom'
    | 'bedroom'
    | 'utilities'
    | 'currentUse'
  )[]
  /** View count by all time */
  viewsForAllTime?: number
  /** View count by day */
  viewsPerDay?: number
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance */
  finance?: LandFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating: number
  /** Return On Investment */
  roi?: number
  /** Profitability index */
  pi?: number
  /** Priority output */
  priority: number
  /**
   * Utilities
   * @default "unknown"
   */
  utilities?: 'yes' | 'no' | 'unknown'
  /** Current use */
  currentUse?: 'residential' | 'commercial' | 'industrial' | 'educational' | 'entertainment' | 'other'
  /** Is favorite */
  favorite: boolean
  /** Files */
  files: UnitFileDTO[]
  /** Country */
  country?: CountryDTO
  /** Token */
  token: TokenResponseDTO
  /** Metrics */
  metrics: MetricsDTO
  /** Tags */
  tags: string[]
  /** Reviews */
  reviews: any[][]
  /** Platform partners */
  partners: PartnerResponseDTO[]
  /** Platform provider */
  provider: ProviderResponseDTO
  /** Referral link */
  referralLink?: ProviderReferralLinkDTO
  /** Similar units */
  similar: UnitSimilarDTO[]
}

export interface NumberFilterDTO {
  /** Greater than equal */
  gte?: number
  /** Less than equal */
  lte?: number
  /** Equal */
  equals?: number
}

export interface UnitFilterDTO {
  /**
   * limit value
   * @min 1
   * @default 10
   */
  limit?: number
  /**
   * offset value
   * @min 0
   * @default 0
   */
  offset?: number
  /** search value */
  search?: string
  /** order value */
  order?: {
    priceUSD?: 'asc' | 'desc'
    'token.priceUSD'?: 'asc' | 'desc'
    viewsPerDay?: 'asc' | 'desc'
    viewsForAllTime?: 'asc' | 'desc'
  }
  /**
   * filter from date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  from?: string
  /**
   * filter to date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  to?: string
  /** Unique ids for filter */
  ids?: string[]
  /** Filter by type */
  type?: ('Estate' | 'Land' | 'Other')[]
  /** Filter by status */
  status?: ('new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted')[]
  /** Filter by country */
  countryId?: string[] | null
  /** Filter by stage */
  tag?: (
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  )[]
  /** Filter by price */
  price?: NumberFilterDTO
  /** Filter by price */
  tokenPrice?: NumberFilterDTO
  /** Filter by minimum investment */
  minimumInvestment?: NumberFilterDTO
}

export interface UnitTokenStatisticResponseDTO {
  /** Current maximum token price */
  priceMax: number
  /** Current minimum token price */
  priceMin: number
  /** Token prices */
  prices: UnitTokenStatisticResponseDTO
}

export interface UnitStatisticsDTO {
  /** Total count */
  total: number
  /** Current maximum price */
  priceMax: number
  /** Current minimum price */
  priceMin: number
  /** Prices */
  prices: object
  /** Minimum investment */
  minimumInvestment: number
  /** Maximum investment */
  maximumInvestment: number
  /** Token statistic */
  token: UnitTokenStatisticResponseDTO
  /** Country count */
  country: object
  /** Tag count */
  tags: object
}

export interface UpdateUnitReferralLinkDTO {
  /** Array of unit ids */
  unitIds: string[]
  /** Referral link unique identifier */
  referralLinkId: string
}

export interface UpdateUnitPayloadDTO {
  /** Token id */
  tokenId?: string
  /** Status */
  status?: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Translations */
  translations?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents?: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Referral link ID */
  referralLinkId?: string
  /** View count by all time */
  viewsForAllTime?: number
  /** View count by day */
  viewsPerDay?: number
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating?: number
  /** Finance */
  finance: EstateFinanceDTO | LandFinanceDTO
  /** Token */
  token: UpdateTokenPayloadDTO
  /** Files */
  files: UnitFilePayloadDTO[]
}

export interface ApproveUnitUpdateDTO {
  /** Unit fields */
  fields: (
    | 'countryId'
    | 'location'
    | 'coords'
    | 'currency'
    | 'price'
    | 'minimumInvestment'
    | 'finance'
    | 'name'
    | 'description'
    | 'translations'
    | 'files'
    | 'documents'
    | 'area'
    | 'ownershipStatus'
    | 'ownershipText'
    | 'referralLinkId'
    | 'stage'
    | 'irr'
    | 'apr'
    | 'bathroom'
    | 'bedroom'
    | 'utilities'
    | 'currentUse'
  )[]
}

export interface UpdateUnitPartnersDTO {
  /** List of exist partner ids */
  partnerIds: string[]
  /** Create partners */
  partners: UpdatePartnerDTO[]
}

export interface UpdateUnitFilePayloadDTO {
  /** Unique identifier */
  type: 'image' | 'document' | 'covers'
}

export interface CreateEstatePayloadDTO {
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance */
  finance?: EstateFinanceDTO | LandFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating?: number
  /** Sanitized name */
  sanitizeName: string
  /** Provider id */
  providerId: string
  /** Token id */
  tokenId?: string
  /** NFT id */
  nftId?: number
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Translations */
  translations?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents?: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Referral link ID */
  referralLinkId?: string
  /** List of exist partner ids */
  partnerIds: string[]
  /** Create partners */
  partners: UpdatePartnerDTO[]
  /** Create provider */
  provider: CreateProviderDTO
  /** Token */
  token: CreateTokenPayloadDTO
  /** Files */
  files: UnitFilePayloadDTO[]
  /** Referral link */
  referralLink?: string
  /** Estate tags */
  tags: 'trending'[]
}

export interface EstateFinanceProjectTotalIncomeSubfieldsDTO {
  /** Projected rental income */
  projectedRentalIncome: number
  /** Projection Appreciation */
  projectionAppreciation: number
}

export interface FinanceGroupDTO {
  /** Summary by group */
  value: number
  /** Subfields */
  subfields: EstateFinanceProjectTotalIncomeSubfieldsDTO
}

export interface EstateFinanceDTO {
  /** Project total income */
  projectTotalIncome: FinanceGroupDTO
  /** Total investment value */
  totalInvestmentValue: FinanceGroupDTO
  /** Annual Cash Flow */
  annualCashFlow: number
  /** Month Cash Flow */
  monthCashFlow: number
  /** Annual Gross Income */
  annualGrossIncome: number
  /** Maintaining costs */
  maintainingCosts: FinanceGroupDTO
  /** Other fees */
  otherFees: number
}

export interface EstateSimilarDTO {
  /** Unique identifier */
  id: string
  /** Status */
  status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Minimum investment value in USD */
  minimumInvestmentUSD?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** View count by all time */
  viewsForAllTime?: number
  /** View count by day */
  viewsPerDay?: number
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance data */
  finance?: EstateFinanceDTO
  /** Is favorite */
  favorite: boolean
  /** Country */
  country: CountryDTO
  /** Files */
  files: any[][]
  /** Token response */
  token: TokenResponseDTO
  /** Tags */
  tags: string[]
  /** Metrics */
  metrics: MetricsDTO
  /** Platform partners */
  partners: PartnerResponseDTO[]
  /** Platform provider */
  provider: ProviderResponseDTO
}

export interface EstateTimelineDTO {
  /** Unique identifier */
  id: string
  /** Unique identifier of estate */
  estateId: string
  /** Stage */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /**
   * Stage date
   * @example "2024-01-01"
   */
  date: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface EstateResponseDTO {
  /** Unique identifier */
  id: string
  /** Sanitized name */
  sanitizeName: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Unit type */
  type: 'Estate' | 'Land' | 'Other'
  /** External url */
  externalUrl: string
  /** Token id */
  tokenId: string
  /** NFT id */
  nftId?: number
  /** Status */
  status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Unit has new data */
  newData: boolean
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Minimum investment value in USD */
  minimumInvestmentUSD?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Provider id */
  providerId: string
  /** External unique identifier */
  externalId: string
  /** Translations */
  translations?: string
  /** Referral link ID */
  referralLinkId?: string
  /** Blocked fields */
  blockedField?: (
    | 'countryId'
    | 'location'
    | 'coords'
    | 'currency'
    | 'price'
    | 'minimumInvestment'
    | 'finance'
    | 'name'
    | 'description'
    | 'translations'
    | 'files'
    | 'documents'
    | 'area'
    | 'ownershipStatus'
    | 'ownershipText'
    | 'referralLinkId'
    | 'stage'
    | 'irr'
    | 'apr'
    | 'bathroom'
    | 'bedroom'
    | 'utilities'
    | 'currentUse'
  )[]
  /** View count by all time */
  viewsForAllTime?: number
  /** View count by day */
  viewsPerDay?: number
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance data */
  finance?: EstateFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating: number
  /** Return On Investment */
  roi?: number
  /** Profitability index */
  pi?: number
  /** Priority output */
  priority: number
  /**
   * Utilities
   * @default "unknown"
   */
  utilities?: 'yes' | 'no' | 'unknown'
  /** Current use */
  currentUse?: 'residential' | 'commercial' | 'industrial' | 'educational' | 'entertainment' | 'other'
  /** Is favorite */
  favorite: boolean
  /** Files */
  files: UnitFileDTO[]
  /** Country */
  country?: CountryDTO
  /** Token */
  token: TokenResponseDTO
  /** Metrics */
  metrics: MetricsDTO
  /** Tags */
  tags: string[]
  /** Reviews */
  reviews: any[][]
  /** Platform partners */
  partners: PartnerResponseDTO[]
  /** Platform provider */
  provider: ProviderResponseDTO
  /** Referral link */
  referralLink?: ProviderReferralLinkDTO
  /** Similar estates */
  similar: EstateSimilarDTO[]
  /** Estate timeline */
  timeline: EstateTimelineDTO[]
}

export interface EstateFilterDTO {
  /**
   * limit value
   * @min 1
   * @default 10
   */
  limit?: number
  /**
   * offset value
   * @min 0
   * @default 0
   */
  offset?: number
  /** search value */
  search?: string
  /** order value */
  order?: {
    roi?: 'asc' | 'desc'
    pi?: 'asc' | 'desc'
    priceUSD?: 'asc' | 'desc'
    'token.priceUSD'?: 'asc' | 'desc'
    apr?: 'asc' | 'desc'
    irr?: 'asc' | 'desc'
    viewsPerDay?: 'asc' | 'desc'
    viewsForAllTime?: 'asc' | 'desc'
  }
  /**
   * filter from date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  from?: string
  /**
   * filter to date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  to?: string
  /** Unique ids for filter */
  ids?: string[]
  /** Filter by status */
  status?: ('new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted')[]
  /** Filter by country */
  countryId?: string[] | null
  /** Filter by bathroom */
  bathroom?: number[]
  /** Filter by bedroom */
  bedroom?: number[]
  /** Filter by stage */
  stage?: (
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  )[]
  /** Filter by stage */
  tag?: (
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  )[]
  /** Filter by price */
  price?: NumberFilterDTO
  /** Filter by price */
  tokenPrice?: NumberFilterDTO
  /** Filter by apr */
  apr?: NumberFilterDTO
  /** Filter by irr */
  irr?: NumberFilterDTO
  /** Filter by minimum investment */
  minimumInvestment?: NumberFilterDTO
}

export interface EstateStatisticGraphRangeDTO {
  /** Field name of range */
  range: number
  /** Count of estates */
  count: number
}

export interface EstateStatisticFilterResponseDTO {
  /** Total count */
  total: number
  /** Current maximum price */
  priceMax: number
  /** Current minimum price */
  priceMin: number
  /**
   * Group ranges
   * @deprecated
   */
  priceRange: EstateStatisticGraphRangeDTO[]
  /** Prices */
  prices: EstateStatisticGraphRangeDTO
  /** Current maximum irr */
  irrMax: number
  /** Current minimum irr */
  irrMin: number
  /**
   * Group ranges
   * @deprecated
   */
  irrRange: EstateStatisticGraphRangeDTO[]
  /** Current maximum apr */
  aprMax: number
  /** Current minimum apr */
  aprMin: number
  /**
   * Group ranges
   * @deprecated
   */
  aprRange: EstateStatisticGraphRangeDTO[]
  /** Current maximum token price */
  tokenPriceMax: number
  /** Current minimum token price */
  tokenPriceMin: number
  /** Group ranges */
  tokenPriceRange: EstateStatisticGraphRangeDTO[]
  /** Token prices */
  tokenPrices: EstateStatisticGraphRangeDTO
  /** Country count */
  country: object
  /** Bathroom count */
  bathroom: object
  /** Bedroom count */
  bedroom: object
  /** Stage count */
  stage: object
  /** Tag count */
  tags: object
  /** Minimum investment */
  minimumInvestment: number
  /** Maximum investment */
  maximumInvestment: number
}

export interface UpdateEstatePriorityDTO {
  /** Array of estate ids */
  estateIds: string[]
}

export interface UpdateEstateReferralLinkDTO {
  /** Array of estate ids */
  estateIds: string[]
  /** Referral link unique identifier */
  referralLinkId: string
}

export interface EstateFilePayloadDTO {
  /** Unique identifier */
  fileId: string
  /** Unique identifier */
  type: 'image' | 'document' | 'covers'
}

export interface UpdateEstatePayloadDTO {
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Translations */
  translations?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents?: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Blocked fields */
  blockedField?: (
    | 'countryId'
    | 'location'
    | 'coords'
    | 'currency'
    | 'price'
    | 'minimumInvestment'
    | 'finance'
    | 'name'
    | 'description'
    | 'translations'
    | 'files'
    | 'documents'
    | 'area'
    | 'ownershipStatus'
    | 'ownershipText'
    | 'referralLinkId'
    | 'stage'
    | 'irr'
    | 'apr'
    | 'bathroom'
    | 'bedroom'
    | 'utilities'
    | 'currentUse'
  )[]
  /**
   * Stage
   * @default "complete"
   */
  stage?:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance data */
  finance?: EstateFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating?: number
  files?: EstateFilePayloadDTO[]
  /** Update token info */
  token?: UpdateTokenPayloadDTO
  /** Referral link */
  referralLink?: ProviderReferralLinkDTO
}

export interface ApproveEstateUpdateDTO {
  /** Estate fields */
  fields: (
    | 'countryId'
    | 'location'
    | 'coords'
    | 'currency'
    | 'price'
    | 'minimumInvestment'
    | 'finance'
    | 'name'
    | 'description'
    | 'translations'
    | 'documents'
    | 'area'
    | 'ownershipStatus'
    | 'ownershipText'
    | 'referralLinkId'
    | 'stage'
    | 'irr'
    | 'apr'
    | 'bathroom'
    | 'bedroom'
    | 'files'
  )[]
}

export interface UpdateEstateFilePayloadDTO {
  /** Unique identifier */
  type: 'image' | 'document' | 'covers'
}

export interface CreateEstateTimelineDTO {
  /** Stage */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /**
   * Stage date
   * @example "2024-01-01"
   */
  date: string
}

export interface CreateTagDTO {
  /** Tag */
  tag: 'new' | 'popular' | 'sold_out' | 'trending'
  /**
   * expiredAt
   * @format date-time
   */
  expiredAt: string
}

export interface TagResponseDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Tag */
  tag: 'new' | 'popular' | 'sold_out' | 'trending'
  /**
   * expiredAt
   * @format date-time
   */
  expiredAt: string
  /** UnitId */
  unitId: string
}

export interface UpdateTagDTO {
  /**
   * expiredAt
   * @format date-time
   */
  expiredAt: string
}

export interface CreateLandDTO {
  /** Finance */
  finance?: EstateFinanceDTO | LandFinanceDTO
  /** Sanitized name */
  sanitizeName: string
  /** Provider id */
  providerId: string
  /** Token id */
  tokenId?: string
  /** NFT id */
  nftId?: number
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Translations */
  translations?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents?: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Referral link ID */
  referralLinkId?: string
  /** List of exist partner ids */
  partnerIds: string[]
  /** Create partners */
  partners: UpdatePartnerDTO[]
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating?: number
  /** Create provider */
  provider: CreateProviderDTO
  /** Token */
  token: CreateTokenPayloadDTO
  /** Files */
  files: UnitFilePayloadDTO[]
  /** Referral link */
  referralLink?: string
  /** Estate tags */
  tags: 'trending'[]
}

export interface LandSimilarDTO {
  /** Unique identifier */
  id: string
  /** Status */
  status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Minimum investment value in USD */
  minimumInvestmentUSD?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** View count by all time */
  viewsForAllTime?: number
  /** View count by day */
  viewsPerDay?: number
  /** Land finance */
  finance?: LandFinanceDTO
  /** Is favorite */
  favorite: boolean
  /** Token payload */
  token?: TokenResponseDTO
  /** Land files */
  files?: any[][]
  /** Metrics */
  metrics: MetricsDTO
  /** Tags */
  tags: string[]
}

export interface LandResponseDTO {
  /** Unique identifier */
  id: string
  /** Sanitized name */
  sanitizeName: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Unit type */
  type: 'Estate' | 'Land' | 'Other'
  /** External url */
  externalUrl: string
  /** Token id */
  tokenId: string
  /** NFT id */
  nftId?: number
  /** Status */
  status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Unit has new data */
  newData: boolean
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Minimum investment value in USD */
  minimumInvestmentUSD?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Provider id */
  providerId: string
  /** External unique identifier */
  externalId: string
  /** Translations */
  translations?: string
  /** Referral link ID */
  referralLinkId?: string
  /** Blocked fields */
  blockedField?: (
    | 'countryId'
    | 'location'
    | 'coords'
    | 'currency'
    | 'price'
    | 'minimumInvestment'
    | 'finance'
    | 'name'
    | 'description'
    | 'translations'
    | 'files'
    | 'documents'
    | 'area'
    | 'ownershipStatus'
    | 'ownershipText'
    | 'referralLinkId'
    | 'stage'
    | 'irr'
    | 'apr'
    | 'bathroom'
    | 'bedroom'
    | 'utilities'
    | 'currentUse'
  )[]
  /** View count by all time */
  viewsForAllTime?: number
  /** View count by day */
  viewsPerDay?: number
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Land finance */
  finance?: LandFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating: number
  /** Return On Investment */
  roi?: number
  /** Profitability index */
  pi?: number
  /** Priority output */
  priority: number
  /**
   * Utilities
   * @default "unknown"
   */
  utilities?: 'yes' | 'no' | 'unknown'
  /** Current use */
  currentUse?: 'residential' | 'commercial' | 'industrial' | 'educational' | 'entertainment' | 'other'
  /** Is favorite */
  favorite: boolean
  /** Files */
  files: UnitFileDTO[]
  /** Country */
  country?: CountryDTO
  /** Token */
  token: TokenResponseDTO
  /** Metrics */
  metrics: MetricsDTO
  /** Tags */
  tags: string[]
  /** Reviews */
  reviews: any[][]
  /** Platform partners */
  partners: PartnerResponseDTO[]
  /** Platform provider */
  provider: ProviderResponseDTO
  /** Referral link */
  referralLink?: ProviderReferralLinkDTO
  /** Similar estates */
  similar: LandSimilarDTO[]
}

export interface LandFilterDTO {
  /**
   * limit value
   * @min 1
   * @default 10
   */
  limit?: number
  /**
   * offset value
   * @min 0
   * @default 0
   */
  offset?: number
  /** search value */
  search?: string
  /** order value */
  order?: {
    priceUSD?: 'asc' | 'desc'
    'token.priceUSD'?: 'asc' | 'desc'
    minimumInvestment?: 'asc' | 'desc'
    viewsPerDay?: 'asc' | 'desc'
    viewsForAllTime?: 'asc' | 'desc'
  }
  /**
   * filter from date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  from?: string
  /**
   * filter to date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  to?: string
  /** Unique ids for filter */
  ids?: string[]
  /** Filter by status */
  status?: ('new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted')[]
  /** Filter by country */
  countryId?: string[] | null
  /** Filter by price */
  price?: NumberFilterDTO
  /** Filter by price */
  tokenPrice?: NumberFilterDTO
  /** Filter by minimum investment */
  minimumInvestment?: NumberFilterDTO
}

export interface LandStatisticGraphRangeDTO {
  /** Field name of range */
  range: number
  /** Count of estates */
  count: number
}

export interface LandStatisticFilterResponseDTO {
  /** Total count */
  total: number
  /** Current maximum price */
  priceMax: number
  /** Current minimum price */
  priceMin: number
  /**
   * Group ranges
   * @deprecated
   */
  priceRange: LandStatisticGraphRangeDTO[]
  /** Prices */
  prices: object
  /** Current maximum token price */
  tokenPriceMax: number
  /** Current minimum token price */
  tokenPriceMin: number
  /** Group ranges */
  tokenPriceRange: LandStatisticGraphRangeDTO[]
  /** Token prices */
  tokenPrices: object
  /** Country count */
  country: object
  /** Minimum investment */
  minimumInvestment: number
  /** Maximum investment */
  maximumInvestment: number
}

export interface UpdateLandDTO {
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents?: UnitDocumentDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Blocked fields */
  blockedField?: (
    | 'countryId'
    | 'location'
    | 'coords'
    | 'currency'
    | 'price'
    | 'minimumInvestment'
    | 'finance'
    | 'name'
    | 'description'
    | 'translations'
    | 'files'
    | 'documents'
    | 'area'
    | 'ownershipStatus'
    | 'ownershipText'
    | 'referralLinkId'
    | 'stage'
    | 'irr'
    | 'apr'
    | 'bathroom'
    | 'bedroom'
    | 'utilities'
    | 'currentUse'
  )[]
  /** Land finance */
  finance?: LandFinanceDTO
  /**
   * Utilities
   * @default "unknown"
   */
  utilities?: 'yes' | 'no' | 'unknown'
  /** Current use */
  currentUse?: 'residential' | 'commercial' | 'industrial' | 'educational' | 'entertainment' | 'other'
  /** Token payload */
  token?: UpdateTokenPayloadDTO
  /** Land files */
  files?: any[][]
}

export interface ApproveLandUpdateDTO {
  /** Land fields */
  fields: (
    | 'countryId'
    | 'location'
    | 'coords'
    | 'currency'
    | 'price'
    | 'minimumInvestment'
    | 'finance'
    | 'name'
    | 'description'
    | 'translations'
    | 'documents'
    | 'area'
    | 'ownershipStatus'
    | 'ownershipText'
    | 'referralLinkId'
    | 'utilities'
    | 'currentUse'
  )[]
}

export interface CreateUnitPartnerDTO {
  /** List of exist partner ids */
  partnerIds?: string
  /** New partner */
  partners?: CreatePartnerDTO[]
}

export interface UserPublicResponseDTO {
  /** Unique identifier */
  id: string
  /** User name */
  username?: string
  /** First name */
  firstName?: string
  /** Last name */
  lastName?: string
  /** Avatar file */
  avatarFile?: FileDTO
}

export interface ReviewResponseDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /** Text review */
  text: string
  /** Review status */
  status: 'New' | 'Moderating' | 'Published' | 'Deleted'
  /** Rating */
  rating: 'Star_1' | 'Star_2' | 'Star_3' | 'Star_4' | 'Star_5'
  /** User */
  user: UserPublicResponseDTO
  /** Unit */
  unit: UnitResponseDTO
}

export interface CreateReviewDTO {
  /** Text review */
  text: string
  /** Rating */
  rating: 'Star_1' | 'Star_2' | 'Star_3' | 'Star_4' | 'Star_5'
  /** User name */
  username?: string
}

export interface UpdateReviewDTO {
  /** Text review */
  text: string
  /** Rating */
  rating: 'Star_1' | 'Star_2' | 'Star_3' | 'Star_4' | 'Star_5'
}

export interface ModerateReviewDTO {
  /** Review status */
  status: 'New' | 'Moderating' | 'Published' | 'Deleted'
}

export interface AddEmailAddressDTO {
  /** email address */
  address: string
}

export interface EmailAddressDTO {
  /** Unique identifier */
  id: string
  /** email address */
  address: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://api.stage.rwa-estate.com'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) {
        if (response.status === 403) {
          const refreshToken = useCookie('acc_refresh_token')
          const token = useCookie('acc_token')

          if (!token.value) {
            token.value = null
            refreshToken.value = null
          } else {
            const decodedToken: any = jwtDecode(token.value)
            const currentTime = Math.floor(Date.now() / 1000)

            if (decodedToken.exp < currentTime) {
              const res = await fetch(`${baseUrl}/api/auth/token/refresh`, {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${refreshToken.value}`,
                  'Content-Type': 'application/json'
                }
              })

              if (res.ok) {
                const data = await res.json()
                token.value = data.accessToken
                refreshToken.value = data.refreshToken
                window.location.reload()
              } else {
                token.value = null
                refreshToken.value = null
                return
              }
            }
          }
        }

        let a: any
        if (response.body && response.body instanceof ReadableStream && !response.body.locked) {
          const reader = response.body.getReader()
          const streamData = await reader.read()
          const text = new TextDecoder().decode(streamData.value)
          try {
            a = JSON.parse(text)
          } catch (error) {
            a = text
          }
        } else {
          a = response.statusText ? { message: response.statusText } : await response[responseFormat || 'json']()
        }
        throw a
      }
      return data
    })
  }
}

/**
 * @title RWA Estate
 * @baseUrl https://api.stage.rwa-estate.com
 * @externalDocs /api/swagger.json
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  constructor() {
    const config = useRuntimeConfig()
    const baseUrl = config.public.baseURL as string

    super({ baseUrl })
  }

  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignOut
     * @summary Logout
     * @request POST:/api/auth/sign-out
     * @secure
     */
    authControllerSignOut: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/sign-out`,
        method: 'POST',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRefresh
     * @summary Refresh JWT token
     * @request POST:/api/auth/token/refresh
     * @secure
     */
    authControllerRefresh: (params: RequestParams = {}) =>
      this.request<JwtResponseDTO, any>({
        path: `/api/auth/token/refresh`,
        method: 'POST',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUp
     * @summary Sign up
     * @request POST:/api/auth/native/sign-up
     */
    authNativeControllerSignUp: (data: SignUpDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/sign-up`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUpCheck
     * @summary Check available username and email
     * @request POST:/api/auth/native/sign-up/check
     */
    authNativeControllerSignUpCheck: (data: SignUpCheckDTO, params: RequestParams = {}) =>
      this.request<void, SignUpCheckResponseDTO>({
        path: `/api/auth/native/sign-up/check`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUpConfirm
     * @summary Sign up confirm
     * @request POST:/api/auth/native/sign-up/confirm
     */
    authNativeControllerSignUpConfirm: (data: SignUpConfirmDTO, params: RequestParams = {}) =>
      this.request<JwtResponseDTO, any>({
        path: `/api/auth/native/sign-up/confirm`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUpAppend
     * @summary Append to exists account
     * @request POST:/api/auth/native/sign-up/append
     */
    authNativeControllerSignUpAppend: (data: SignUpAppendDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/sign-up/append`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUpAppendConfirm
     * @summary Append to exists account
     * @request POST:/api/auth/native/sign-up/append/confirm
     */
    authNativeControllerSignUpAppendConfirm: (data: SignUpAppendConfirmDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/sign-up/append/confirm`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUpResend
     * @summary Resend sign up
     * @request POST:/api/auth/native/sign-up/resend
     */
    authNativeControllerSignUpResend: (data: SignUpResendDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/sign-up/resend`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignIn
     * @summary Sign in
     * @request POST:/api/auth/native/sign-in
     */
    authNativeControllerSignIn: (data: SignInDTO, params: RequestParams = {}) =>
      this.request<JwtResponseDTO, any>({
        path: `/api/auth/native/sign-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerPasswordRecovery
     * @summary Password recovery
     * @request POST:/api/auth/native/password-recovery
     */
    authNativeControllerPasswordRecovery: (data: PasswordRecoveryDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/password-recovery`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerPasswordRecoveryConfirm
     * @summary Password recovery confirm
     * @request POST:/api/auth/native/password-recovery/confirm
     */
    authNativeControllerPasswordRecoveryConfirm: (data: PasswordRecoveryConfirmDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/password-recovery/confirm`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerPasswordChange
     * @summary Password change
     * @request POST:/api/auth/native/password-change
     * @secure
     */
    authNativeControllerPasswordChange: (data: PasswordChangeDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/password-change`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerPasswordChangeConfirm
     * @summary Password change confirm
     * @request POST:/api/auth/native/password-change/confirm
     * @secure
     */
    authNativeControllerPasswordChangeConfirm: (data: PasswordChangeConfirmDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/password-change/confirm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * @description Accepts query parameters: authCode, scope and grantType for authorization in Google OAuth2.0. If parameters are not passed, redirects to the Google OAuth2.0 authorization form. Allows authorization in two ways: 1. Full authorization on the BackEnd, steps: - Front redirects to the Google authorization address on the backend - Back redirects to Google - The user logs in to Google and is redirected to the backend - Backend returns jwt tokens 2. Partial authorization FrontEnd + BackEnd, steps: - FrontEnd performs a redirect to Google OAuth2.0 - The user, having logged into Google, is redirected to FrontEnd - FrontEnd redirects the received query parameters to BackEnd - Backend returns jwt tokens
     *
     * @tags Auth
     * @name AuthGoogleControllerAuth
     * @summary Google Auth
     * @request GET:/api/auth/google
     */
    authGoogleControllerAuth: (params: RequestParams = {}) =>
      this.request<JwtResponseDTO, any>({
        path: `/api/auth/google`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetMe
     * @summary Requests profile
     * @request GET:/api/users/me
     * @secure
     */
    usersControllerGetMe: (params: RequestParams = {}) =>
      this.request<UserDTO, any>({
        path: `/api/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerUpdate
     * @summary Update profile
     * @request PUT:/api/users/me
     * @secure
     */
    usersControllerUpdate: (data: UpdateUserDTO, params: RequestParams = {}) =>
      this.request<UserDTO, any>({
        path: `/api/users/me`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerList
     * @summary Get providers
     * @request GET:/api/providers
     * @secure
     */
    providersControllerList: (params: RequestParams = {}) =>
      this.request<ProviderResponseDTO[], UnauthorizedExceptionDTO>({
        path: `/api/providers`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerV2ListReferralLinks
     * @summary Get provider referral links
     * @request GET:/api/providers/{providerId}/referral-links
     * @secure
     */
    providersControllerV2ListReferralLinks: (name?: string, providerId?: string, params: RequestParams = {}) =>
      this.request<ProviderReferralLinkDTO[], UnauthorizedExceptionDTO | void>({
        path: `/api/providers/${providerId}/referral-links`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerV2CreateReferralLink
     * @summary Create provider referral link
     * @request POST:/api/providers/{providerId}/referral-links
     * @secure
     */
    providersControllerV2CreateReferralLink: (
      data: CreateProviderReferralLinkDTO,
      name?: string,
      providerId?: string,
      params: RequestParams = {}
    ) =>
      this.request<ProviderReferralLinkDTO, BadRequestExceptionDTO | UnauthorizedExceptionDTO | NotFoundExceptionDTO>({
        path: `/api/providers/${providerId}/referral-links`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerV2UpdateReferralLink
     * @summary Update provider referral link.
     * @request PATCH:/api/providers/{providerId}/referral-links/{id}
     * @secure
     */
    providersControllerV2UpdateReferralLink: (
      id: string,
      data: UpdateProviderReferralLinkDTO,
      name?: string,
      providerId?: string,
      params: RequestParams = {}
    ) =>
      this.request<ProviderReferralLinkDTO, BadRequestExceptionDTO | UnauthorizedExceptionDTO | NotFoundExceptionDTO>({
        path: `/api/providers/${providerId}/referral-links/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerV2DeleteReferralLink
     * @summary Delete provider referral link.
     * @request DELETE:/api/providers/{providerId}/referral-links/{id}
     * @secure
     */
    providersControllerV2DeleteReferralLink: (
      id: string,
      name?: string,
      providerId?: string,
      params: RequestParams = {}
    ) =>
      this.request<void, UnauthorizedExceptionDTO | NotFoundExceptionDTO>({
        path: `/api/providers/${providerId}/referral-links/${id}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerListReferralLinks
     * @summary Get provider referral links
     * @request GET:/api/providers/{name}/referral-links
     * @deprecated
     * @secure
     */
    providersControllerListReferralLinks: (name?: string, providerId?: string, params: RequestParams = {}) =>
      this.request<ProviderReferralLinkDTO[], UnauthorizedExceptionDTO | void>({
        path: `/api/providers/${name}/referral-links`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerCreateReferralLink
     * @summary Create provider referral link
     * @request POST:/api/providers/{name}/referral-links
     * @deprecated
     * @secure
     */
    providersControllerCreateReferralLink: (
      data: CreateProviderReferralLinkDTO,
      name?: string,
      providerId?: string,
      params: RequestParams = {}
    ) =>
      this.request<ProviderReferralLinkDTO, BadRequestExceptionDTO | UnauthorizedExceptionDTO | NotFoundExceptionDTO>({
        path: `/api/providers/${name}/referral-links`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerUpdateReferralLink
     * @summary Update provider referral link.
     * @request PATCH:/api/providers/{name}/referral-links/{id}
     * @deprecated
     * @secure
     */
    providersControllerUpdateReferralLink: (
      id: string,
      data: UpdateProviderReferralLinkDTO,
      name?: string,
      providerId?: string,
      params: RequestParams = {}
    ) =>
      this.request<ProviderReferralLinkDTO, BadRequestExceptionDTO | UnauthorizedExceptionDTO | NotFoundExceptionDTO>({
        path: `/api/providers/${name}/referral-links/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerDeleteReferralLink
     * @summary Delete provider referral link.
     * @request DELETE:/api/providers/{name}/referral-links/{id}
     * @deprecated
     * @secure
     */
    providersControllerDeleteReferralLink: (
      id: string,
      name?: string,
      providerId?: string,
      params: RequestParams = {}
    ) =>
      this.request<void, UnauthorizedExceptionDTO | NotFoundExceptionDTO>({
        path: `/api/providers/${name}/referral-links/${id}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesControllerList
     * @summary Use this endpoint to list companies
     * @request GET:/api/companies
     * @secure
     */
    companiesControllerList: (params: RequestParams = {}) =>
      this.request<CompanyResponseDTO[], any>({
        path: `/api/companies`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesControllerCreate
     * @summary Use this endpoint to create company
     * @request POST:/api/companies
     * @secure
     */
    companiesControllerCreate: (data: CreateCompanyDTO, params: RequestParams = {}) =>
      this.request<CompanyResponseDTO, any>({
        path: `/api/companies`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesControllerRetrieve
     * @summary Use this endpoint to get company
     * @request GET:/api/companies/{companyId}
     * @secure
     */
    companiesControllerRetrieve: (companyId: string, params: RequestParams = {}) =>
      this.request<CompanyResponseDTO, any>({
        path: `/api/companies/${companyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesControllerUpdate
     * @summary Use this endpoint to update company
     * @request PATCH:/api/companies/{companyId}
     * @secure
     */
    companiesControllerUpdate: (companyId: string, data: UpdateCompanyDTO, params: RequestParams = {}) =>
      this.request<CompanyResponseDTO, any>({
        path: `/api/companies/${companyId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesControllerDelete
     * @summary Use this endpoint to delete company
     * @request DELETE:/api/companies/{companyId}
     * @secure
     */
    companiesControllerDelete: (companyId: string, params: RequestParams = {}) =>
      this.request<CompanyResponseDTO, any>({
        path: `/api/companies/${companyId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Partners
     * @name PartnersControllerList
     * @summary Use this endpoint to get partners
     * @request GET:/api/partners
     * @secure
     */
    partnersControllerList: (
      query?: {
        /**
         * limit value
         * @min 1
         * @default 10
         */
        limit?: number
        /**
         * offset value
         * @min 0
         * @default 0
         */
        offset?: number
        /** search value */
        search?: string
        /**
         * filter from date
         * @format date-time
         * @example "2020-06-15T10:30:50.000Z"
         */
        from?: string
        /**
         * filter to date
         * @format date-time
         * @example "2020-06-15T10:30:50.000Z"
         */
        to?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PartnerResponseDTO[], any>({
        path: `/api/partners`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Partners
     * @name PartnersControllerCreate
     * @summary Use this endpoint to create partner
     * @request POST:/api/partners
     * @secure
     */
    partnersControllerCreate: (data: CreatePartnerDTO, params: RequestParams = {}) =>
      this.request<PartnerResponseDTO, any>({
        path: `/api/partners`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Partners
     * @name PartnersControllerUpdate
     * @summary Use this endpoint to update partner
     * @request PATCH:/api/partners/{partnerId}
     * @secure
     */
    partnersControllerUpdate: (partnerId: string, data: UpdatePartnerDTO, params: RequestParams = {}) =>
      this.request<PartnerResponseDTO, any>({
        path: `/api/partners/${partnerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Partners
     * @name PartnersControllerDelete
     * @summary Use this endpoint to delete partner
     * @request DELETE:/api/partners/{partnerId}
     * @secure
     */
    partnersControllerDelete: (partnerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/partners/${partnerId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Chains
     * @name ChainsControllerList
     * @summary List all chains
     * @request GET:/api/chains
     */
    chainsControllerList: (params: RequestParams = {}) =>
      this.request<ChainDTO[], any>({
        path: `/api/chains`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Charts
     * @name ChartsControllerGetCharts
     * @summary Get charts
     * @request GET:/api/charts
     */
    chartsControllerGetCharts: (
      query: {
        /**
         * Resolution
         * @default "hour"
         */
        resolution: 'minute' | 'hour' | 'day' | 'week' | 'month'
        /**
         * Period
         * @default "month"
         */
        period: 'year' | 'half-year' | 'month' | 'week' | 'day'
        /**
         * filter from date
         * @format date-time
         * @example "2020-06-15T10:30:50.000Z"
         */
        from?: string
        /**
         * filter to date
         * @format date-time
         * @example "2020-06-15T10:30:50.000Z"
         */
        to?: string
        /** Token ID */
        tokenId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<GetChartsResponseDTO[], any>({
        path: `/api/charts`,
        method: 'GET',
        query: query,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Countries
     * @name CountriesControllerList
     * @summary List all countries
     * @request GET:/api/countries
     */
    countriesControllerList: (params: RequestParams = {}) =>
      this.request<CountryDTO[], any>({
        path: `/api/countries`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Currency rates
     * @name CurrencyRatesControllerList
     * @summary Use this endpoint to list currency rates
     * @request GET:/api/currency-rates
     */
    currencyRatesControllerList: (
      query?: {
        /** List of currency */
        currency?: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/currency-rates`,
        method: 'GET',
        query: query,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadFile
     * @summary Use this endpoint to upload file
     * @request POST:/api/files
     * @secure
     */
    filesControllerUploadFile: (
      data: {
        /** @format binary */
        file?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<FileDTO, void>({
        path: `/api/files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerImport
     * @summary Use this endpoint to import file from url
     * @request PUT:/api/files
     * @secure
     */
    filesControllerImport: (data: ImportFileDTO, params: RequestParams = {}) =>
      this.request<FileDTO[], any>({
        path: `/api/files`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerList
     * @summary Use this endpoint to list token
     * @request GET:/api/tokens
     */
    tokensControllerList: (params: RequestParams = {}) =>
      this.request<PaginationResultDto, any>({
        path: `/api/tokens`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerCreate
     * @summary Use this endpoint to create token
     * @request POST:/api/tokens
     * @secure
     */
    tokensControllerCreate: (data: CreateTokenPayloadDTO, params: RequestParams = {}) =>
      this.request<TokenResponseDTO, any>({
        path: `/api/tokens`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerRetrieve
     * @summary Use this endpoint to retrieve token
     * @request GET:/api/tokens/{tokenId}
     */
    tokensControllerRetrieve: (tokenId: string, params: RequestParams = {}) =>
      this.request<TokenResponseDTO, any>({
        path: `/api/tokens/${tokenId}`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerUpdate
     * @summary Use this endpoint to update token
     * @request PATCH:/api/tokens/{tokenId}
     * @secure
     */
    tokensControllerUpdate: (tokenId: string, data: UpdateTokenPayloadDTO, params: RequestParams = {}) =>
      this.request<TokenResponseDTO, any>({
        path: `/api/tokens/${tokenId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerDelete
     * @summary Use this endpoint to delete token
     * @request DELETE:/api/tokens/{tokenId}
     * @secure
     */
    tokensControllerDelete: (tokenId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/tokens/${tokenId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerUpdateIssuer
     * @summary Use this endpoint to update token issuer
     * @request PATCH:/api/tokens/{tokenId}/issuer
     * @secure
     */
    tokensControllerUpdateIssuer: (tokenId: string, data: UpdateTokenIssuerDTO, params: RequestParams = {}) =>
      this.request<TokenResponseDTO, any>({
        path: `/api/tokens/${tokenId}/issuer`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerCreate
     * @summary Use this endpoint to create unit
     * @request POST:/api/units
     * @secure
     */
    unitsControllerCreate: (data: CreateUnitPayloadDTO, params: RequestParams = {}) =>
      this.request<UnitResponseDTO, any>({
        path: `/api/units`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerGetStats
     * @summary Use this endpoint to retrieve unit count by filter
     * @request POST:/api/units/stats
     */
    unitsControllerGetStats: (data: UnitFilterDTO, params: RequestParams = {}) =>
      this.request<UnitStatisticsDTO, any>({
        path: `/api/units/stats`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerListPost
     * @summary Use this endpoint to list units
     * @request POST:/api/units/filter
     */
    unitsControllerListPost: (data: UnitFilterDTO, params: RequestParams = {}) =>
      this.request<PaginationResultDto, any>({
        path: `/api/units/filter`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerUpdateReferralLink
     * @summary Use this endpoint to update unit referral link
     * @request PATCH:/api/units/referralLink
     */
    unitsControllerUpdateReferralLink: (data: UpdateUnitReferralLinkDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/units/referralLink`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerRetrieve
     * @summary Use this endpoint to retrieve unit
     * @request GET:/api/units/{unitIdOrName}
     */
    unitsControllerRetrieve: (unitIdOrName: string, params: RequestParams = {}) =>
      this.request<UnitResponseDTO, any>({
        path: `/api/units/${unitIdOrName}`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerUpdateMetrics
     * @summary Use this endpoint to store metrics
     * @request PUT:/api/units/{unitId}
     */
    unitsControllerUpdateMetrics: (unitId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/units/${unitId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerUpdate
     * @summary Use this endpoint to update unit
     * @request PATCH:/api/units/{unitId}
     * @secure
     */
    unitsControllerUpdate: (unitId: string, data: UpdateUnitPayloadDTO, params: RequestParams = {}) =>
      this.request<UnitResponseDTO, any>({
        path: `/api/units/${unitId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerDelete
     * @summary Use this endpoint to delete unit
     * @request DELETE:/api/units/{unitId}
     * @secure
     */
    unitsControllerDelete: (unitId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/units/${unitId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerGetShadow
     * @summary Use this endpoint to get shadow unit
     * @request GET:/api/units/{unitId}/approve
     * @secure
     */
    unitsControllerGetShadow: (unitId: string, params: RequestParams = {}) =>
      this.request<UnitResponseDTO, any>({
        path: `/api/units/${unitId}/approve`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerApprove
     * @summary Use this endpoint to approve unit fields
     * @request PATCH:/api/units/{unitId}/approve
     * @secure
     */
    unitsControllerApprove: (unitId: string, data: ApproveUnitUpdateDTO, params: RequestParams = {}) =>
      this.request<UnitResponseDTO, any>({
        path: `/api/units/${unitId}/approve`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerUpdateStatus
     * @summary Use this endpoint to update unit status
     * @request PATCH:/api/units/{unitId}/status/{status}
     * @secure
     */
    unitsControllerUpdateStatus: (
      unitId: string,
      status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted',
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/units/${unitId}/status/${status}`,
        method: 'PATCH',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerUpdatePartners
     * @summary Use this endpoint to update partners of unit
     * @request PATCH:/api/units/{unitId}/partners
     * @secure
     */
    unitsControllerUpdatePartners: (unitId: string, data: UpdateUnitPartnersDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/units/${unitId}/partners`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Partners, Units
     * @name PartnersControllerList2
     * @summary Use this endpoint to get partners for unit
     * @request GET:/api/units/{unitId}/partners
     * @originalName partnersControllerList
     * @duplicate
     * @secure
     */
    partnersControllerList2: (unitId: string, params: RequestParams = {}) =>
      this.request<PartnerResponseDTO[], any>({
        path: `/api/units/${unitId}/partners`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Partners, Units
     * @name PartnersControllerCreate2
     * @summary Use this endpoint to create partner for unit
     * @request POST:/api/units/{unitId}/partners
     * @originalName partnersControllerCreate
     * @duplicate
     * @secure
     */
    partnersControllerCreate2: (unitId: string, data: CreateUnitPartnerDTO, params: RequestParams = {}) =>
      this.request<PartnerResponseDTO, any>({
        path: `/api/units/${unitId}/partners`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units, Users
     * @name UnitsControllerFavorite
     * @summary Use this endpoint to favorite unit
     * @request PATCH:/api/units/{unitId}/favorite
     * @secure
     */
    unitsControllerFavorite: (unitId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/units/${unitId}/favorite`,
        method: 'PATCH',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Units
     * @name UnitsControllerUpdateFileType
     * @summary Use this endpoint to update type of unit file
     * @request PATCH:/api/units/{unitId}/file/{fileId}
     * @secure
     */
    unitsControllerUpdateFileType: (
      unitId: string,
      fileId: string,
      data: UpdateUnitFilePayloadDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/units/${unitId}/file/${fileId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerCreate
     * @summary Use this endpoint to create estate
     * @request POST:/api/estates
     * @deprecated
     * @secure
     */
    estatesControllerCreate: (data: CreateEstatePayloadDTO, params: RequestParams = {}) =>
      this.request<EstateResponseDTO, any>({
        path: `/api/estates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerGetStats
     * @summary Use this endpoint to retrieve estate count by filter
     * @request POST:/api/estates/stats
     */
    estatesControllerGetStats: (data: EstateFilterDTO, params: RequestParams = {}) =>
      this.request<EstateStatisticFilterResponseDTO, any>({
        path: `/api/estates/stats`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerListPost
     * @summary Use this endpoint to list estate
     * @request POST:/api/estates/filter
     * @deprecated
     */
    estatesControllerListPost: (data: EstateFilterDTO, params: RequestParams = {}) =>
      this.request<PaginationResultDto, any>({
        path: `/api/estates/filter`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerSetPriority
     * @summary Use this endpoint to setup priority of estate
     * @request PATCH:/api/estates/priority
     * @deprecated
     * @secure
     */
    estatesControllerSetPriority: (data: UpdateEstatePriorityDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/priority`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerGetFilterStatsPost
     * @summary Use this endpoint to retrieve estate count by filter
     * @request POST:/api/estates/stats/filters
     * @deprecated
     */
    estatesControllerGetFilterStatsPost: (data: EstateFilterDTO, params: RequestParams = {}) =>
      this.request<EstateStatisticFilterResponseDTO, any>({
        path: `/api/estates/stats/filters`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerGetFiles
     * @summary Use this endpoint to retrieve all estate files
     * @request GET:/api/estates/files
     * @deprecated
     * @secure
     */
    estatesControllerGetFiles: (
      query: {
        /** Unique identifier */
        type: 'image' | 'document' | 'covers'
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/estates/files`,
        method: 'GET',
        query: query,
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdateReferralLink
     * @summary Use this endpoint to update estate referral link
     * @request PATCH:/api/estates/referralLink
     * @deprecated
     */
    estatesControllerUpdateReferralLink: (data: UpdateEstateReferralLinkDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/referralLink`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerRetrieve
     * @summary Use this endpoint to retrieve estate
     * @request GET:/api/estates/{estateId}
     * @deprecated
     */
    estatesControllerRetrieve: (estateId: string, params: RequestParams = {}) =>
      this.request<EstateResponseDTO, any>({
        path: `/api/estates/${estateId}`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdateMetrics
     * @summary Use this endpoint to store metrics
     * @request PUT:/api/estates/{estateId}
     * @deprecated
     */
    estatesControllerUpdateMetrics: (estateId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdate
     * @summary Use this endpoint to update estate
     * @request PATCH:/api/estates/{estateId}
     * @deprecated
     * @secure
     */
    estatesControllerUpdate: (estateId: string, data: UpdateEstatePayloadDTO, params: RequestParams = {}) =>
      this.request<EstateResponseDTO, any>({
        path: `/api/estates/${estateId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerDelete
     * @summary Use this endpoint to delete estate
     * @request DELETE:/api/estates/{estateId}
     * @deprecated
     * @secure
     */
    estatesControllerDelete: (estateId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerGetShadow
     * @summary Use this endpoint to get shadow estate
     * @request GET:/api/estates/{estateId}/approve
     * @deprecated
     * @secure
     */
    estatesControllerGetShadow: (estateId: string, params: RequestParams = {}) =>
      this.request<EstateResponseDTO, any>({
        path: `/api/estates/${estateId}/approve`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerApprove
     * @summary Use this endpoint to approve estate
     * @request PATCH:/api/estates/{estateId}/approve
     * @deprecated
     * @secure
     */
    estatesControllerApprove: (estateId: string, data: ApproveEstateUpdateDTO, params: RequestParams = {}) =>
      this.request<EstateResponseDTO, any>({
        path: `/api/estates/${estateId}/approve`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdateStatus
     * @summary Use this endpoint to update estate status
     * @request PATCH:/api/estates/{estateId}/status/{status}
     * @deprecated
     * @secure
     */
    estatesControllerUpdateStatus: (
      estateId: string,
      status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted',
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/status/${status}`,
        method: 'PATCH',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdateFileType
     * @summary Use this endpoint to update type of estate file
     * @request PATCH:/api/estates/{estateId}/file/{fileId}
     * @deprecated
     * @secure
     */
    estatesControllerUpdateFileType: (
      estateId: string,
      fileId: string,
      data: UpdateEstateFilePayloadDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/file/${fileId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * @description Setup estate timeline stage date. If stage already exists, it will be updated
     *
     * @tags Estates
     * @name EstatesControllerUpdateTimeline
     * @summary Use this endpoint to setup timeline of estate
     * @request PUT:/api/estates/{estateId}/timeline
     * @secure
     */
    estatesControllerUpdateTimeline: (estateId: string, data: CreateEstateTimelineDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/timeline`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerDeleteTimeline
     * @summary Use this endpoint to delete estate timeline
     * @request DELETE:/api/estates/{estateId}/timeline/{stage}
     * @secure
     */
    estatesControllerDeleteTimeline: (
      estateId: string,
      stage?:
        | 'in_progress'
        | 'complete'
        | 'ConstructionLaunched'
        | 'ConstructionFinished'
        | 'ListingDate'
        | 'PropertyAvaliableForRental'
        | 'StartOfFirstPayments',
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/timeline/${stage}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates, Users
     * @name EstatesControllerFavorite
     * @summary Use this endpoint to favorite estate
     * @request PATCH:/api/estates/{estateId}/favorite
     * @deprecated
     * @secure
     */
    estatesControllerFavorite: (estateId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/favorite`,
        method: 'PATCH',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tags, Units
     * @name TagsControllerCreate
     * @summary Use this endpoint to create tag
     * @request POST:/api/units/{unitId}/tags
     * @secure
     */
    tagsControllerCreate: (unitId: string, data: CreateTagDTO, params: RequestParams = {}) =>
      this.request<TagResponseDTO, any>({
        path: `/api/units/${unitId}/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tags, Units
     * @name TagsControllerModerate
     * @summary Use this endpoint to update tag
     * @request PATCH:/api/units/{unitId}/tags/{tag}
     * @secure
     */
    tagsControllerModerate: (
      unitId: string,
      tag: 'new' | 'popular' | 'sold_out' | 'trending',
      data: UpdateTagDTO,
      params: RequestParams = {}
    ) =>
      this.request<TagResponseDTO, any>({
        path: `/api/units/${unitId}/tags/${tag}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerCreate
     * @summary Use this endpoint to create land
     * @request POST:/api/lands
     * @deprecated
     * @secure
     */
    landsControllerCreate: (data: CreateLandDTO, params: RequestParams = {}) =>
      this.request<LandResponseDTO, any>({
        path: `/api/lands`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerGetStats
     * @summary Use this endpoint to retrieve land count by filter
     * @request POST:/api/lands/stats
     */
    landsControllerGetStats: (data: LandFilterDTO, params: RequestParams = {}) =>
      this.request<LandStatisticFilterResponseDTO, any>({
        path: `/api/lands/stats`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerList
     * @summary Use this endpoint to list land
     * @request POST:/api/lands/filter
     * @deprecated
     */
    landsControllerList: (data: LandFilterDTO, params: RequestParams = {}) =>
      this.request<PaginationResultDto, any>({
        path: `/api/lands/filter`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerRetrieve
     * @summary Use this endpoint to retrieve land
     * @request GET:/api/lands/{landId}
     * @deprecated
     */
    landsControllerRetrieve: (landId: string, params: RequestParams = {}) =>
      this.request<LandResponseDTO, any>({
        path: `/api/lands/${landId}`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerUpdateMetrics
     * @summary Use this endpoint to store metrics
     * @request PUT:/api/lands/{landId}
     * @deprecated
     */
    landsControllerUpdateMetrics: (landId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/lands/${landId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerUpdate
     * @summary Use this endpoint to update land
     * @request PATCH:/api/lands/{landId}
     * @deprecated
     * @secure
     */
    landsControllerUpdate: (landId: string, data: UpdateLandDTO, params: RequestParams = {}) =>
      this.request<LandResponseDTO, any>({
        path: `/api/lands/${landId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerDelete
     * @summary Use this endpoint to delete land
     * @request DELETE:/api/lands/{landId}
     * @deprecated
     * @secure
     */
    landsControllerDelete: (landId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/lands/${landId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerGetShadow
     * @summary Use this endpoint to get shadow estate
     * @request GET:/api/lands/{landId}/approve
     * @deprecated
     * @secure
     */
    landsControllerGetShadow: (landId: string, params: RequestParams = {}) =>
      this.request<LandResponseDTO, any>({
        path: `/api/lands/${landId}/approve`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerApprove
     * @summary Use this endpoint to approve estate
     * @request PATCH:/api/lands/{landId}/approve
     * @deprecated
     * @secure
     */
    landsControllerApprove: (landId: string, data: ApproveLandUpdateDTO, params: RequestParams = {}) =>
      this.request<LandResponseDTO, any>({
        path: `/api/lands/${landId}/approve`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerUpdateFileType
     * @summary Use this endpoint to update type of land file
     * @request PATCH:/api/lands/{landId}/file/{fileId}
     * @deprecated
     * @secure
     */
    landsControllerUpdateFileType: (
      landId: string,
      fileId: string,
      data: UpdateEstateFilePayloadDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/lands/${landId}/file/${fileId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands, Users
     * @name LandsControllerFavorite
     * @summary Use this endpoint to favorite land
     * @request PATCH:/api/lands/{landId}/favorite
     * @deprecated
     * @secure
     */
    landsControllerFavorite: (landId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/lands/${landId}/favorite`,
        method: 'PATCH',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerUpdateStatus
     * @summary Use this endpoint to update estate status
     * @request PATCH:/api/lands/{landId}/status/{status}
     * @deprecated
     * @secure
     */
    landsControllerUpdateStatus: (
      landId: string,
      status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted',
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/lands/${landId}/status/${status}`,
        method: 'PATCH',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Partners, Units
     * @name PartnersControllerUpdate2
     * @summary Use this endpoint to update partner for unit
     * @request PATCH:/api/units/{unitId}/partners/{partnerId}
     * @originalName partnersControllerUpdate
     * @duplicate
     * @secure
     */
    partnersControllerUpdate2: (
      unitId: string,
      partnerId: string,
      data: UpdatePartnerDTO,
      params: RequestParams = {}
    ) =>
      this.request<PartnerResponseDTO, any>({
        path: `/api/units/${unitId}/partners/${partnerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Partners, Units
     * @name PartnersControllerDelete2
     * @summary Use this endpoint to delete partner for unit
     * @request DELETE:/api/units/{unitId}/partners/{partnerId}
     * @originalName partnersControllerDelete
     * @duplicate
     * @secure
     */
    partnersControllerDelete2: (unitId: string, partnerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/units/${unitId}/partners/${partnerId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Reviews, Units
     * @name ReviewsControllerList
     * @summary Use this endpoint to get reviews
     * @request GET:/api/units/{unitId}/reviews
     * @secure
     */
    reviewsControllerList: (unitId: string, params: RequestParams = {}) =>
      this.request<ReviewResponseDTO[], any>({
        path: `/api/units/${unitId}/reviews`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Reviews, Units
     * @name ReviewsControllerCreate
     * @summary Use this endpoint to create review
     * @request POST:/api/units/{unitId}/reviews
     * @secure
     */
    reviewsControllerCreate: (unitId: string, data: CreateReviewDTO, params: RequestParams = {}) =>
      this.request<ReviewResponseDTO, any>({
        path: `/api/units/${unitId}/reviews`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Reviews, Units
     * @name ReviewsControllerUpdate
     * @summary Use this endpoint to update review
     * @request PATCH:/api/units/{unitId}/reviews/{reviewId}
     * @secure
     */
    reviewsControllerUpdate: (unitId: string, reviewId: string, data: UpdateReviewDTO, params: RequestParams = {}) =>
      this.request<ReviewResponseDTO, any>({
        path: `/api/units/${unitId}/reviews/${reviewId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Reviews, Units
     * @name ReviewsControllerDelete
     * @summary Use this endpoint to delete review
     * @request DELETE:/api/units/{unitId}/reviews/{reviewId}
     * @secure
     */
    reviewsControllerDelete: (unitId: string, reviewId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/units/${unitId}/reviews/${reviewId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Reviews, Units
     * @name ReviewsControllerModerate
     * @summary Use this endpoint to moderate review
     * @request PATCH:/api/units/{unitId}/reviews/{reviewId}/moderate
     * @secure
     */
    reviewsControllerModerate: (
      unitId: string,
      reviewId: string,
      data: ModerateReviewDTO,
      params: RequestParams = {}
    ) =>
      this.request<ReviewResponseDTO, any>({
        path: `/api/units/${unitId}/reviews/${reviewId}/moderate`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerList2
     * @summary Use this endpoint to get reviews
     * @request GET:/api/reviews
     * @originalName reviewsControllerList
     * @duplicate
     * @secure
     */
    reviewsControllerList2: (
      query?: {
        /**
         * limit value
         * @min 1
         * @default 10
         */
        limit?: number
        /**
         * offset value
         * @min 0
         * @default 0
         */
        offset?: number
        /** search value */
        search?: string
        /**
         * filter from date
         * @format date-time
         * @example "2020-06-15T10:30:50.000Z"
         */
        from?: string
        /**
         * filter to date
         * @format date-time
         * @example "2020-06-15T10:30:50.000Z"
         */
        to?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ReviewResponseDTO[], any>({
        path: `/api/reviews`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdate2
     * @summary Use this endpoint to update review
     * @request PATCH:/api/reviews/{reviewId}
     * @originalName reviewsControllerUpdate
     * @duplicate
     * @secure
     */
    reviewsControllerUpdate2: (reviewId: string, data: UpdateReviewDTO, params: RequestParams = {}) =>
      this.request<ReviewResponseDTO, any>({
        path: `/api/reviews/${reviewId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDelete2
     * @summary Use this endpoint to delete review
     * @request DELETE:/api/reviews/{reviewId}
     * @originalName reviewsControllerDelete
     * @duplicate
     * @secure
     */
    reviewsControllerDelete2: (reviewId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/reviews/${reviewId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerModerate2
     * @summary Use this endpoint to moderate review
     * @request PATCH:/api/reviews/{reviewId}/moderate
     * @originalName reviewsControllerModerate
     * @duplicate
     * @secure
     */
    reviewsControllerModerate2: (reviewId: string, data: ModerateReviewDTO, params: RequestParams = {}) =>
      this.request<ReviewResponseDTO, any>({
        path: `/api/reviews/${reviewId}/moderate`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Mailing list
     * @name MailingListControllerRetrieve
     * @summary Use this endpoint to add mail to mailing list
     * @request POST:/api/mailing-list/email
     */
    mailingListControllerRetrieve: (data: AddEmailAddressDTO, params: RequestParams = {}) =>
      this.request<EmailAddressDTO, any>({
        path: `/api/mailing-list/email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      })
  }
}
